import React from "react";

import { Container, Row, Col } from "reusecore/Layout";
import SectionTitle from "reusecore/SectionTitle";
import Button from "reusecore/Button";
import VintageBox from "reusecore/VintageBox";

import imgAbout from "assets/images/app/about.png";
import particle1 from "assets/images/app/particle/05.png";
import particle2 from "assets/images/app/particle/06.png";

import AboutSectionWrapper from "./about.style";

const About = () => {
    return (
        <AboutSectionWrapper id="about">
            <img className="section__particle one" src={particle1} alt="img" />
            <img className="section__particle two" src={particle2} alt="img" />
            <Container fullWidthSM>
                <Row Vcenter={true}>
                    <Col xs={12} sm={6}>
                        <img
                            className="about-thumb"
                            src={imgAbout}
                            alt="This is about UNI-Prime"
                        />
                    </Col>
                    <Col xs={12} sm={6} className="about-content-wrap">
                        <SectionTitle
                            className="section-title"
                            leftAlign={true}
                            UniWidth="100%"
                        >
                            {" "}
                            <h4>About us</h4>
                            <h2>
                                <span>Starting with MyCorp</span> is easier than anything.
                            </h2>
                        </SectionTitle>
                        <p className="about-text text-one">
                            Work Instantly. That is our motto. MyCorp enables you and your 
                            staffs to instantly get the jobs done. 
                        </p>
                        <p className="about-text text-two">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                        </p>
                        <VintageBox
                            right={true}
                            vintageTwo={true}
                            position="relative"
                        >
                            <Button>Learn More</Button>
                        </VintageBox>
                    </Col>
                </Row>
            </Container>
        </AboutSectionWrapper>
    );
};

export default About;
